
import { defineComponent } from 'vue';
import gaussian from 'gaussian';

declare global {
  interface Window {
    Plotly: any;
    firebase: any;
  }
}

type RoundStats = {
  expectedValue: number;
  pctChanceToWin: number;
  multiplier: number;
  stack: number;
  kellyStack: number;
  riskedAmount: number;
  kellyRisked: number;
  roundWon: boolean;
}

export default defineComponent({
  name: 'Home',
  created() {
    if (this.$route.query?.roundLimit) {
      const { roundLimit } = this.$route.query;
      if (typeof roundLimit === 'string') {
        this.roundLimit = parseInt(roundLimit, 10);
      }
    }
    this.nextRound();
    window.matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (event) => {
        this.darkTheme = event.matches;
      });
  },
  computed: {
    ss(): string {
      if (this.darkTheme) {
        return '/ss-dark.png';
      }
      return '/ss.png';
    },
    multiplier(): number {
      return this.expectedValue / (this.pctChanceToWin / 100);
    },
    simpleMultiplier(): number {
      return Math.max(1, Math.floor(this.expectedValue / (this.pctChanceToWin / 1000)) / 10);
    },
    expectedValue(): number {
      const evDistribution = gaussian(1.05, 0.1);
      return evDistribution.ppf(this.evRandom);
    },
    actualExpectedValue(): number {
      return this.simpleMultiplier * (this.pctChanceToWin / 100);
    },
    kellySize(): number {
      if (this.actualExpectedValue < 1) {
        return 0;
      }
      const pct100 = this.pctChanceToWin / 100;
      return pct100 - (1 - pct100) / (this.simpleMultiplier - 1);
    },
    lastRound(): object {
      if (this.roundHistory.length === 0) {
        return {};
      }

      return this.roundHistory[this.roundHistory.length - 1];
    },
  },
  methods: {
    // helloWorld() {
    //   fetch('https://us-central1-beat-kelly.cloudfunctions.net/corsEnabledFunction', {
    //     method: 'POST',
    //     body: JSON.stringify({ u: [20000, 15000, 10000, 10000], k: [20001, 15001, 10001] }),
    //   })
    //     .then((response) => response.json())
    //     .then((data) => console.log(data));
    //   // const helloWorldCall = window.firebase.functions().httpsCallable('helloWorld');

    //   // helloWorldCall({ demir: 'can' })
    //   //   .then((res: any) => {
    //   //     console.log('res');
    //   //     console.log(res);
    //   //   })
    //   //   .catch((err: any) => {
    //   //     console.log('err');
    //   //     console.log(err);
    //   //   });
    // },
    nextRound() {
      if (this.riskedAmount === '') {
        return;
      }

      const riskedAmount = Number.parseInt(this.riskedAmount, 10);
      if (riskedAmount > this.stack) {
        return;
      }

      if (riskedAmount < 0) {
        this.noNegative = true;
        return;
      }

      this.noNegative = false;

      if (this.round > 0) {
        const kellyRisked = Number.parseInt((this.kellySize * this.kellyStack).toFixed(), 10);
        const stackBefore = this.stack;
        const kellyStackBefore = this.kellyStack;

        if (Math.random() * 100 < this.pctChanceToWin) { // won
          this.roundWon = true;
          this.stack = this.stack - riskedAmount + (riskedAmount * this.simpleMultiplier);
          this.stack = Number.parseInt(this.stack.toFixed(), 10);
          this.kellyStack = this.kellyStack - kellyRisked + (kellyRisked * this.simpleMultiplier);
          this.kellyStack = Number.parseInt(this.kellyStack.toFixed(), 10);
        } else {
          this.roundWon = false;
          this.stack -= riskedAmount;
          this.kellyStack -= kellyRisked;
        }

        this.roundHistory.push({
          expectedValue: this.actualExpectedValue,
          pctChanceToWin: this.pctChanceToWin,
          multiplier: this.simpleMultiplier,
          stack: stackBefore,
          kellyStack: kellyStackBefore,
          roundWon: this.roundWon,
          riskedAmount,
          kellyRisked,
        });
      }

      this.riskedAmount = '';
      this.evRandom = ((Math.random() * 80) + 20) / 100;
      this.pctChanceToWin = 5 + Math.floor(Math.random() * 90);
      this.round += 1;
      if (this.round > this.roundLimit) {
        this.onGameOver();
      }
    },
    onGameOver() {
      this.gameOver = true;
      const stack = {
        x: [...this.roundHistory.map((r, i) => i), this.roundLimit],
        y: [...this.roundHistory.map(((r) => r.stack)), this.stack],
        type: 'scatter',
        name: 'Your performance',
      };

      const kellyStack = {
        x: [...this.roundHistory.map((r, i) => i), this.roundLimit],
        y: [...this.roundHistory.map(((r) => r.kellyStack)), this.kellyStack],
        type: 'scatter',
        name: 'Kelly performance',
      };

      const data = [stack, kellyStack];
      const layout = {
        title: 'Your performance against Kelly',
        // eslint-disable-next-line @typescript-eslint/camelcase
        paper_bgcolor: 'white',
        // eslint-disable-next-line @typescript-eslint/camelcase
        plot_bgcolor: 'white',
        font: {
          color: '#444',
        },
      };

      if (this.darkTheme) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        layout.paper_bgcolor = '#222';
        // eslint-disable-next-line @typescript-eslint/camelcase
        layout.plot_bgcolor = '#222';
        layout.font = {
          color: '#fff',
        };
      }

      let text = 'You perform as well as Kelly!';
      if (this.stack > this.kellyStack) {
        text = 'You beat Kelly!';
      } else if (this.stack < this.kellyStack) {
        text = 'Kelly beat you!';
      }

      this.resultText = text;
      this.result = {
        text,
        stack,
        kellyStack,
      };

      if (window.Plotly) {
        window.Plotly.newPlot('results', data, layout);
      }
    },
  },
  data() {
    return {
      darkTheme: window.matchMedia('(prefers-color-scheme: dark)').matches,
      roundHistory: [] as Array<RoundStats>,
      showHints: false,
      gameStarted: false,
      details: false,
      noNegative: false,
      riskedAmount: '0',
      evRandom: 0,
      gameOver: false,
      stack: 10000,
      kellyStack: 10000,
      round: 0,
      roundLimit: 50,
      roundWon: false,
      pctChanceToWin: 0.5,
      resultText: '',
      result: {},
    };
  },
});
